export default function countRemainingChallenges(remainingChallenges) {
  if (!remainingChallenges) return 0
  let remainingChallengesNum = 0
  if (!remainingChallenges?.complete_profile) {
    remainingChallengesNum++
  }
  if (
    remainingChallenges?.daily_login &&
    !remainingChallenges?.daily_login?.is_today_collected
  ) {
    remainingChallengesNum++
  }
  if (remainingChallenges?.unanswered_main_quiz) {
    remainingChallengesNum++
  }
  if (remainingChallenges?.unread_posts?.length > 0) {
    remainingChallengesNum =
      remainingChallengesNum + remainingChallenges?.unread_posts?.length
  }
  // if can refer this month
  if (remainingChallenges?.better_friend?.can_refer_this_month) {
    remainingChallengesNum++
  }
  return remainingChallengesNum
}
